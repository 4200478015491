import {Buffer} from "buffer";
import {Features, IPlatformUser} from "../types";

const DEFAULT_TAX_RAT = 0.2;

function getFeatures(platformUser: IPlatformUser): Features {
    let result: Features;
    const features: any = platformUser.features;
    if (features?.type === "Buffer") {
        const bufferString = Buffer.from(features?.data).toString("utf8");
        result = JSON.parse(bufferString);
    } else if (features) {
        result = JSON.parse(features);
    } else {
        result = {
            notifAtSave: false,
            notifDayBefore: false,
            syncAgenda: true,
        };
    }
    return result;
}

function getTaxRate(platformUser: IPlatformUser): number {
    let result = 0;
    if (platformUser.vatId) {
        result = DEFAULT_TAX_RAT;
    }
    return result;
}

export const featureService = {
    getFeatures,
    getTaxRate,
};
